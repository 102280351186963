import React from "react";
import "./Animation.css";
const Animation = () => {
  return (
    <div className="animation-part">
      <div className="container-fluid">
        <div className="col-11 col-lg-12 mx-auto">
          <div className="tech-slideshow">
            <div className="mover-1"></div>
          </div>
          <div className="tech-slideshow2">
            <div className="mover-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
